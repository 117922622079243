.header {
  $this: &;

  display: flex;
  position: relative;
  z-index: 10;

  align-items: center;

  height: $header-height;

  background-color: get-color(header-background);

  font-size: font-size(15px);

  @include media-query(sm) {
    height: $header-height-sm;
  }

  &__sections {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  &__section {
    display: flex;
    flex: 1 1 auto;
    align-items: center;

    max-width: 100%;

    &--hamburger {
      flex-basis: 50px;
      max-width: 50px;
    }

    &--middle {
      justify-content: center;

      @include media-query(sm) {
        flex-basis: 10%;
        max-width: 10%;
      }
    }

    &--left {
      @include media-query(sm) {
        flex-basis: 45%;
        max-width: 45%;
      }
    }

    &--right {
      justify-content: flex-end;
      flex-basis: 50px;

      max-width: 50px;

      @include media-query(sm) {
        flex-basis: 45%;
        max-width: 45%;
      }
    }
  }

  &__social {
    @include media-query(sm) {
      margin-left: -16px;
    }

    svg {
      width: 18px;
      height: 18px;
    }
  }

  &__logo {
    @include font-heading();

    width: 100%;

    text-align: center;
    font-size: font-size-heading(24px);
  }

  &__logo-link {
    display: block;
    color: get-color(header-link);
    text-decoration: none;
  }

  &__logo-image {
    display: block;

    object-fit: contain;
    object-position: center center;

    margin: 0 auto;
    padding-top: 8px;
    padding-bottom: 8px;

    width: 100%;
    max-width: $logo-max-width;
    max-height: $header-height;

    @include media-query(sm) {
      max-height: $header-height-sm;
    }
  }

  &__list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    &--socials {
      #{$this}__list-item {
        margin-right: 8px;
        margin-left: 0;
      }
    }

    &--toolbar {
      #{$this}__link-icon {
        margin: 0;
        svg {
          width: 18px;
          height: 18px;
        }
      }

      #{$this}__link {
        padding: 11px 15px;
      }
    }
  }

  &__list-item {
    flex: 0 0 auto;
    position: relative;

    & > * + * {
      margin-left: 8px;
    }

    &:hover {
      #{$this}__dropdown {
        display: block;
      }
    }

    &--breadcrumbs {
      &:not(:last-child) {
        &::after {
          content: '→';
        }
      }

      &:first-child {
        display: none;

        @include media-query(sm) {
          display: block;
        }
      }
    }

    &--separator {
      &:not(:last-child) {
        &::after {
          content: '⸳';
        }
      }

      &:first-child {
        display: none;

        @include media-query(sm) {
          display: block;
        }
      }
    }
  }

  &__link {
    padding: 11px 19px;
    color: get-color(header-link);
    text-decoration: none;

    &:hover {
      color: get-color(header-link-hover);
    }

    &--icon {
      display: flex;
      align-items: center;
      cursor: pointer;
      line-height: 14px;

      &.active {
        #{$this}__link-icon {
          transform: rotate(180deg);
          transition: transform 0.3s ease;
        }
      }
    }
  }

  &__link-icon {
    margin-left: 8px;

    svg {
      width: 12px;
      height: 12px;
    }
  }

  &__icon {
    display: block;
    position: relative;
    padding: 11px 16px;
    color: get-color(header-link);

    &:hover {
      color: get-color(header-link-hover);
    }

    &--hamburger {
      margin-left: -16px;

      @include media-query(sm) {
        margin-left: 0;
      }
    }

    &--cart {
      margin-right: -16px;

      @include media-query(sm) {
        margin-right: 0;
      }
    }

    svg {
      width: 18px;
      height: 18px;
    }
  }

  &__cta {
    margin-left: 11px;
  }

  &__social-badge {
    position: absolute;
    top: -10px;
    right: 8px;
    background-color: get-color(live-badge-background);
    color: get-color(live-badge-text);
    border-radius: 25px;
    animation: 5s blink ease infinite;
    transition-delay: 5s;
    padding: 0 6px;
    font-size: 10px;
    font-weight: bold;
    text-transform: uppercase;

    @keyframes blink {
      from, to {
        opacity: 0;
      }
      50% {
        opacity: 1;
      }
    }
  }

  &__dropdown {
    display: none;

    position: absolute;
    z-index: 1;
    left: 8px;

    margin: 0;

    min-width: 120px;

    text-align: left;

    &--content {
      left: 7px;
      min-width: 70px;
      overflow: hidden;

      #{$this}__dropdown-inner {
        max-height: 200px;
        overflow: auto;

        &::-webkit-scrollbar {
          width: 4px;
          background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: var(--input-corner-radius);
          -webkit-box-shadow: inset 0 0 6px rgba(get-color(primary), 0.3);
          background-color: get-color(primary);
        }

        &::-webkit-scrollbar-track {
          -webkit-box-shadow: inset 0 0 6px rgba(get-color(primary), 0.3);
          border-radius: var(--input-corner-radius);
          background-color: transparent;
        }

        &::-webkit-scrollbar-thumb:window-inactive {
          background-color: transparent;
        }
      }
    }
  }

  &__dropdown-inner {
    display: grid;
    overflow: hidden;

    gap: 12px;

    border: 1px solid rgba(get-color(on-background-rgb), 0.1);
    border-radius: $input-border-radius;
    padding: 11px 15px;

    background: get-color(background);
  }

  &__dropdown-link {
    color: get-color(header-link);
    white-space: nowrap;
    text-decoration: none;
    cursor: pointer;

    &:hover {
      color: get-color(header-link-hover);
    }
  }
}
